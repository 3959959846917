<template>
  <section class="relative text-primary body-font animate-fade-in-up font-main">
    <ProfileIcon :itemId="Number(itemId)" />
    <!-- CLaim Dashboard -->
    <ViewClaimableView v-if="pageProgress == ''" :nftItems="nftItems" :nftInfos="nftInfos" :isblocked="isblocked" @onPinNFT="onPinNFT" @onSelectGift="onSelectGift" />
    <SelectClaimableView v-if="pageProgress == 'select'" :nftItems="nftItems" :nftInfos="nftInfos" :isblocked="isblocked" :isNftSelected="isNftSelected" @updateGridSelection="updateGridSelection" @onPinNFT="onPinNFT" @confirmSelection="confirmSelection" />
    <ConfirmNFTView v-if="pageProgress == 'confirm'" :isOwner="isOwner" :selectedNftItem="lastSelectedNft" @onConfirmNFT="onClaimNFT" @onPinNFT="onPinNFT" :txProgress="txProgress" />
    <!-- Top Right Info -->
    <!-- <div id="bottomLeft"></div>-->
    <div class="absolute -bottom-10 -left-12 md:-bottom-24 md:-left-12 -z-1 overflow-hidden">
      <img src="../assets/icons/AME_0x-2_BLUE.png" alt="" class="w-40 md:w-64 object-contain transform -rotate-12" />
    </div>
  </section>
</template>

<script>
const axios = require('axios')
import Web3 from 'web3'
import ProfileIcon from '../components/ProfileIcon.vue'
import NftGrid from '../components/NftGrid.vue'
import BaseTransferABISource from '../assets/abi/OPbuild.json'
import DOJIABISource from '../assets/abi/DojiCrew.json'
import CourierABISource from '../assets/abi/DojiClaimsProxy.json'
import Accounting721ABISource from '../assets/abi/Doji721Accounting.json'
import Accounting1155ABISource from '../assets/abi/Doji1155Accounting.json'
import ViewClaimableView from '../components/ViewClaimable.vue'
import SelectClaimableView from '../components/SelectClaimable.vue'
import ConfirmNFTView from '../components/ConfirmNFT.vue'

export default {
  name: 'Claim',
  components: {
    ProfileIcon,
    ViewClaimableView,
    SelectClaimableView,
    ConfirmNFTView,
  },
  data() {
    return {
      itemId: this.$route.params.number,
      unclaimedEthAmount: null,
      dojiABI: DOJIABISource.abi,
      dojiContracts: process.env.VUE_APP_DOJI_CONTRACT,
      courierABI: CourierABISource.abi,
      courierContracts: process.env.VUE_APP_COURIER_CONTRACT,
      Accounting721ABI: Accounting721ABISource.abi,
      Accounting721Contracts: process.env.VUE_APP_COURIER_721_ACCOUNTING,
      Accounting1155ABI: Accounting1155ABISource.abi,
      Accounting1155Contracts: process.env.VUE_APP_COURIER_1155_ACCOUNTING,
      address: null,
      balance: null,
      contract: null,
      chainid: null,
      ownedItems: null,
      isblocked: false,
      nftInfos: null,
      baseAssetsURL: process.env.VUE_APP_BASE_ASSETS_URL,
      tokenIds: null,
      nftContracts: null,
      nftItems: [],
      selectedNft: { url: '' },
      lastSelectedNft: { url: '' },
      isNftSelected: false,
      baseDojiImgURL: process.env.VUE_APP_BASE_DOJI_IMG_URL,
      pageProgress: '',
      isOwner: false,
      txProgress: '',
    }
  },
  watch: {
    async nftInfos(nftInfos) {
      if (this.nftInfos.length) {
        this.getAssetInfo(nftInfos)
      }
    },
  },
  computed: {},
  async mounted() {
    if (typeof web3 !== 'undefined') {
      web3 = new Web3(web3.currentProvider)
    } else {
      web3 = new Web3(new Web3.providers.HttpProvider(process.env.VUE_APP_WEB3_PROVIDER))
    }
    await this.checkContractBalance()
  },
  methods: {
    async onClaimEth() {
      console.log('ETH Claim Pressed')
      // Connect Metamask
      if (!this.address) {
        await this.handleEthereum()
      }
      this.ownedItems = await this.checkOwnership()
      if (this.ownedItems.includes(String(this.itemId))) {
        console.log('Owner Detected')
        await this.claimEth()
      } else {
        console.log("You don't own this Floot")
        this.isblocked = true
        setTimeout(() => (this.isblocked = false), 2000)
      }
    },
    async onClaimNFT() {
      console.log('NFT Claim Pressed')
      this.txProgress = "processing"
      // Connect Metamask
      if (!this.address) {
        await this.handleEthereum()
      }
      this.ownedItems = await this.checkOwnership()
      if (this.ownedItems.includes(String(this.itemId))) {
        console.log('Floot Owner Detected')
        await this.claimNFT()
      } else {
        console.log("You don't own this Floot")
        this.isblocked = true
        setTimeout(() => (this.isblocked = false), 2000)
      }
    },
    async handleEthereum() {
      web3 = new Web3(Web3.givenProvider)
      console.log(web3)
      const { ethereum } = window
      if (ethereum && ethereum.isMetaMask) {
        console.log('Ethereum successfully detected!')
        await this.accountInfo()
      } else {
        console.log('Please install MetaMask!')
      }
    },
    async accountInfo() {
      if (this.address) {
        let prevAddress = this.address
        let newAddress = await this.addressLookup()
        if (prevAddress != newAddress) {
          this.address = newAddress
          this.isblocked = false
        }
      } else {
        this.address = await this.addressLookup()
      }
    },
    async addressLookup() {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      const account = accounts[0]
      return account
    },
    async checkOwnership() {
      console.log(`checking ownership`)
      const contract = new web3.eth.Contract(this.dojiABI, this.dojiContracts)
      let ownedItems = await contract.methods.walletInventory(this.address).call()
      console.log('Items Owned: ', ownedItems)
      return ownedItems
    },
    async claimNFT() {
      await this.handleEthereum()
      if (this.lastSelectedNft.contractType == "ERC721") {
        // handle 721s
        let drainPayout = await this.claim721NFT()
        if (drainPayout) {
          console.log(`Successfully Claimed NFT`)
          this.txProgress = "done"
          // Remove NFT from Grid
          this.nftItems = this.nftItems.filter((nftItem) => !(nftItem.url == this.lastSelectedNft.url))
        } else {
          console.log(`Failed to claim ETH`)
        }
      } else if (this.lastSelectedNft.contractType == "ERC1155") {
        let drainPayout = await this.claim1155NFT()
        if (drainPayout) {
          console.log(`Successfully Claimed NFT`)
          this.txProgress = "done"
          // Remove NFT from Grid
          this.nftItems = this.nftItems.filter((nftItem) => !(nftItem.url == this.lastSelectedNft.url))
        } else {
          console.log(`Failed to claim ETH`)
        }
      }
    },
    async claim721NFT() {
      console.log(`selected tokenID: ${this.lastSelectedNft.token_id}`)
      console.log(`selected nftContract: ${this.lastSelectedNft.contract_address}`)
      const contract = new web3.eth.Contract(this.courierABI, this.courierContracts)
      const drainPayout = await new Promise((resolve, reject) => {
        contract.methods.claimOneNFTPending(this.itemId, this.lastSelectedNft.contract_address, Number(this.lastSelectedNft.token_id)).send({ from: this.address, value: 0 }, function (error, transactionHash) {
          if (transactionHash) resolve(transactionHash)
          else reject()
        })
      })
      return drainPayout
    },
    async claim1155NFT() {
      console.log(`selected tokenID: ${this.lastSelectedNft.token_id}`)
      console.log(`selected nftContract: ${this.lastSelectedNft.contract_address}`)
      const contract = new web3.eth.Contract(this.courierABI, this.courierContracts)
      const drainPayout = await new Promise((resolve, reject) => {
        contract.methods.claimOne1155Pending(this.itemId, this.lastSelectedNft.contract_address, this.lastSelectedNft.token_id, this.lastSelectedNft.amount).send({ from: this.address, value: 0 }, function (error, transactionHash) {
          if (transactionHash) resolve(transactionHash)
          else reject()
        })
      })
      return drainPayout
    },
    async checkContractBalance() {
      console.log(`721 accounting: ${this.Accounting721Contracts}`)
      console.log(`1155 accounting: ${this.Accounting1155Contracts}`)
      const Accounting721Contract = new web3.eth.Contract(this.Accounting721ABI, this.Accounting721Contracts)
      const Accounting1155Contract = new web3.eth.Contract(this.Accounting1155ABI, this.Accounting1155Contracts)
      // Get 721s
      let nft721Balance = await Accounting721Contract.methods.viewNFTsPending(this.itemId).call()
      console.log(`721 NFTs Pending:`)
      console.log(nft721Balance)
      if (nft721Balance) {
        this.nftInfos = nft721Balance.filter((nftInfo) => nftInfo.claimed == false)
      }
      // Get 1155s
      var nft1155Balance = []
      let addressCount = await Accounting1155Contract.methods.getContractAddressCount(this.itemId).call()
      console.log(`address count: ${addressCount}`)
      if (addressCount > 0) {
        for ( let i = 0 ; i < addressCount; i++ ) {
          let tokenAddress = await Accounting1155Contract.methods.getContractAddressByIndex(this.itemId, i).call()
          let tokenCount = await Accounting1155Contract.methods.getTokenIDCount(tokenAddress, this.itemId).call()
          for ( let j = 0 ; j < tokenCount; j++ ) {
            let nftTokenId = await Accounting1155Contract.methods.getTokenIDByIndex(tokenAddress, this.itemId, j).call()
            let nftBalance = await Accounting1155Contract.methods.getTokenBalanceByID(tokenAddress, this.itemId, nftTokenId).call()
            let nftInfo = {
              nftContract: tokenAddress,
              tokenID: nftTokenId,
              balance: nftBalance,
              claimed: nftBalance == "0" ? true : false,
              is1155: true,
            }
            nft1155Balance.push(nftInfo)
          }
        }
        console.log(`1155 NFTs Pending:`)
        console.log(nft1155Balance)
      } else {
        console.log(`No 1155 NFTs Pending`)
      }
      if (nft1155Balance) {
        if (this.nftInfos) {
          this.nftInfos.push(...nft1155Balance.filter((nftInfo) => nftInfo.claimed == false))
        } else {
          this.nftInfos = nft1155Balance.filter((nftInfo) => nftInfo.claimed == false)
        }
        console.log(`nftInfo:`)
        console.log(this.nftInfos)
      } else {
        console.log(`No NFTs pending`)
      }
    },
    onPinNFT() {
      this.$router.push({ name: 'Gift', params: { number: this.itemId } })
    },
    async confirmSelection() {
      // Connect Metamask
      if (!this.address) {
        await this.handleEthereum()
      }
      this.ownedItems = await this.checkOwnership()
      this.isOwner = this.ownedItems.includes(String(this.itemId))
      if (process.env.NODE_ENV == 'development') {
        this.isOwner = true
      }
      console.log(`isOwner: ${this.isOwner}`)
      console.log(`itemId: ${this.itemId}`)
      this.pageProgress = 'confirm'
    },
    onSelectGift() {
      this.pageProgress = "select"
    },
    updateGridSelection(selectedNftItem) {
      // Deselect all
      this.nftItems.map((nftItem) => (nftItem.selected = false))
      // Deselect if same Selection else select new
      // console.log(`lastURL: ${this.lastSelectedNft.url}`)
      // console.log(`newURL: ${selectedNftItem.url}`)

      //  update Selection UI Highlight and store last selected items
      if (this.lastSelectedNft.url == selectedNftItem.url) {
        this.lastSelectedNft = { url: '' }
        this.isNftSelected = false
        console.log(`Deslected NFT`)
      } else {
        // Select New Item
        this.nftItems.map((nftItem) => {
          if (nftItem.url == selectedNftItem.url) {
            nftItem.selected = true
          }
        })
        this.lastSelectedNft = selectedNftItem
        this.isNftSelected = true
        console.log(`Selected NFT:`)
        console.log(this.lastSelectedNft)
      }
    },
    // Get NFTs from Asset API
    async getAssetInfo(nftInfos) {
      if (nftInfos) {
        // Store NFT info
        this.tokenIds = nftInfos.map((nftInfo) => nftInfo.tokenID)
        this.nftContracts = nftInfos.map((nftInfo) => nftInfo.nftContract)
        // Create Params
        var params = new URLSearchParams()
        params.append('owner', process.env.VUE_APP_COURIER_CONTRACT)
        params.append('order_direction', 'asc')
        params.append('offset', 0)
        params.append('limit', 50)
        this.tokenIds.forEach((tokenId) => {
          params.append('token_ids', tokenId)
        })
        this.nftContracts.forEach((nftContract) => {
          params.append('asset_contract_addresses', nftContract)
        })

        var query = {
          headers: { 'X-API-KEY': "3dc45512b01949868961e4842026021e" },
          params: params,
        }
        // Axios Call
        try {
          await axios.get(this.baseAssetsURL, query).then((response) => {
            if (response.status == 200) {
              this.nftItems = []
              let assets = response.data.assets
              if (assets) {
                assets.forEach((asset, i) => {
                  let nftItem = {
                    name: asset.collection.name ? asset.collection.name : asset.asset_contract.name,
                    token_id: asset.token_id,
                    contract_address: asset.asset_contract.address,
                    image_url: asset.image_thumbnail_url ? asset.image_thumbnail_url : asset.collection.image_url ? asset.collection.image_url : asset.asset_contract.image_url,
                    url: asset.permalink,
                    slug: asset.collection.slug,
                    selected: false,
                    contractType: asset?.asset_contract?.schema_name,
                    amount: nftInfos[i]?.balance
                  }
                  this.nftItems.push(nftItem)
                  // dev purpose only
                  // Array.apply(null, Array(11)).forEach(() => {
                  // 	this.nftItems.push(nftItem)
                  // })
                })
                console.log(`NFTItems:`)
                console.log(this.nftItems)
              } else {
                console.log('No NFTs deposited')
              }
            } else {
              console.log('Error: \n', response)
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style scoped>
#bottomLeft {
  background-image: url('../assets/icons/AME-0X.png');
  width: 150px;
  background-position: 50% 50%;
  background-size: cover;
  position: absolute;
  left: -20;
  bottom: -20;
  transform: rotate(20deg);
}
</style>
