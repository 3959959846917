<template>
  <div class="container mx-auto h-screen flex px-4 items-center justify-center flex-col">
    <div class="text-center lg:w-2/3 w-full">
      <div class="flex flex-col justify-center">
        <div class="mt-8 flex flex-col">
          <div class="text-4xl font-bold text-center mb-2">{{ nftInfos ? (nftInfos.length ? 'OH NICE!' : 'No Gifts YET!') : 'The Courier is searching for gifts...' }}</div>
          <div class="text-xl font-medium text-center">
            {{ nftInfos ? (nftInfos.length ? `It seems as if this DOJI has gifts!` : 'stay tuned') : '' }}
          </div>
          <div class="text-xl font-medium text-center">
          </div>
        </div>
        <div class="my-2 h-1/3">
          <NftGrid v-if="nftItems.length" :nftItems="nftItems" @updateGridSelection="updateGridSelection" :continue_loading="false" />
          <!-- Lottie -->
          <lottie v-else-if="!nftItems.length" :height="150" class="max-h-40 w-24" :options="lottieOptions" @animCreated="handleAnimation" />
        </div>
        <!-- <div class="font-bold text-lg text-center mb-2">
          {{ nftInfos ? (nftInfos.length ? 'Is this your DOJI?' : '') : '' }}
        </div> -->
        <div v-if="nftItems.length" class="w-full mt-4">
          <button v-if="!isblocked" @click="onPinNFT()" class="w-full inline-flex justify-center bg-accent uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">SEND GIFT</button>
          <div class="font-bold text-lg text-center mb-2">
          {{ nftInfos ? (nftInfos.length ? 'Is this your DOJI?' : '') : '' }} <button @click="onSelectGift()" class="text-primary underline text-xl capitalize">Claim Gifts</button>
        </div>
          <!-- <button v-if="isblocked" disabled class="w-full inline-flex justify-center bg-warning uppercase text-background text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">you don't own this</button> -->
        </div>
        <div v-if="!nftItems.length" class="w-full mt-4">
          <button v-if="!isblocked" @click="onPinNFT()" class="w-full inline-flex justify-center bg-accent uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">SEND GIFT</button>
        </div>
        <router-link :to="{ name: 'Home' }" class="text-primary underline text-xl capitalize mt-4">go back</router-link>
        <!-- development use only -->
        <!-- <button @click="updatePayout()" class="inline-flex justify-center bg-accent uppercase text-background text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4 mt-8">
          Update Payout
        </button> -->
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import NftGrid from '../components/NftGrid.vue'
import lottie from 'vue-lottie/src/lottie.vue'
import * as animationData from "@/assets/lottie/lottie_loading_animation.json";

export default {
  name: 'ViewClaimableView',
  components: {
    NftGrid,
    lottie,
  },
  props: {
    nftInfos: Array,
    nftItems: Array,
    isblocked: Boolean,
    
  },
  data() {
    return {
      lottieAnimation: null,
      lottieOptions: { animationData: animationData.default }
    }
  },
  methods: {
    updateGridSelection(selectedNftItem) {
      // do nothing
    },
    onPinNFT() {
      this.$emit('onPinNFT')
    },
    onSelectGift() {
      this.$emit('onSelectGift')
    },
    handleAnimation: function (lottieAnimation) {
      this.lottieAnimation = lottieAnimation;
    },
  },
}
</script>

<style>
</style>