<template>
  <section class="relative text-primary body-font animate-fade-in-up font-main">
    <ProfileIcon v-if="itemId == '?'" :itemId="Number(itemId)" />
    <!-- NFT Dashboard -->
    <ViewGiftableView v-if="pageProgress == ''" :nftItems="nftItems" :isblocked="isblocked" @updateGridSelection="updateGridSelection" @confirmSelection="confirmSelection" @load_batch_nft_data="load_batch_nft_data" :itemId="String(itemId)" :continue_loading="continue_loading" />
    <ConfirmGiftNFTView v-if="pageProgress == 'confirm'" :txProgress="txProgress" :selectedNftItem="lastSelectedNft" :luckyReciever="String(luckyReciever)" :warningMessage="warningMessage" @onConfirmNFT="onGiftNFT" @onGoBack="onGoBack" />
    <!-- <div id="bottomLeft"></div>-->
    <div class="absolute -bottom-10 -left-12 md:-bottom-24 md:-left-12 -z-1 overflow-hidden">
      <img src="../assets/icons/AME_0x-2_BLUE.png" alt="" class="w-40 md:w-64 object-contain transform -rotate-12" />
    </div>
  </section>
</template>

<script>
const axios = require('axios')
import Web3 from 'web3'
import ProfileIcon from '../components/ProfileIcon.vue'
import NftGrid from '../components/NftGrid.vue'
import ERC721 from '../assets/abi/ERC721.json'
import ERC1155 from '../assets/abi/ERC1155.json'
import ACC721 from '../assets/abi/Doji721Accounting.json'
import Courier from '../assets/abi/DojiClaimsProxy.json'
import ACC1155 from '../assets/abi/Doji1155Accounting.json'
import Doji from '../assets/abi/DojiCrew.json'
import ViewGiftableView from '../components/ViewGiftable.vue'
import ConfirmGiftNFTView from '../components/ConfirmGiftNFT.vue'

export default {
  components: {
    ProfileIcon,
    NftGrid,
    ViewGiftableView,
    ConfirmGiftNFTView,
  },
  data() {
    return {
      itemId: this.$route.params.number,
      courierContractAddress: process.env.VUE_APP_COURIER_CONTRACT,
      acc721ContractsAddress: process.env.VUE_APP_COURIER_721_ACCOUNTING,
      acc1155ContractsAddress: process.env.VUE_APP_COURIER_1155_ACCOUNTING,
      dojiContract: process.env.VUE_APP_DOJI_CONTRACT,
      address: null,
      balance: null,
      contract: null,
      chainid: null,
      nftInfos: null,
      baseAssetsURL: process.env.VUE_APP_BASE_ASSETS_URL,
      tokenIds: null,
      nftContracts: null,
      nftItems: [],
      ownedItems: [],
      selectedNft: { url: '' },
      lastSelectedNft: { url: '' },
      baseDojiImgURL: process.env.VUE_APP_BASE_DOJI_IMG_URL,
      pageProgress: '',
      isblocked: false,
      txProgress: '',
      erc721Contract: null,
      erc1150Contract: null,
      luckyReciever: '',
      continue_loading: true,
      warningMessage: null,
    }
  },
  async mounted() {
    await this.handleEthereum()
    await this.load_batch_nft_data()
    const contract = new web3.eth.Contract(Doji.abi, this.dojiContract)
    this.ownedItems = await contract.methods.walletInventory(this.address).call()
  },
  methods: {
    async confirmSelection() {
      // Connect Metamask
      if (!this.address) {
        await this.handleEthereum()
      }
      this.pageProgress = 'confirm'
    },
    updateGridSelection(selectedNftItem) {
      // Deselect all
      this.nftItems.map((nftItem) => (nftItem.selected = false))
      // Deselect if same Selection else select new
      // console.log(`lastURL: ${this.lastSelectedNft.url}`)
      // console.log(`newURL: ${selectedNftItem.url}`)

      //  update Selection UI Highlight and store last selected items
      if (this.lastSelectedNft.url == selectedNftItem.url) {
        this.lastSelectedNft = { url: '' }
        console.log(`Deslected NFT`)
      } else {
        // Select New Item
        this.nftItems.map((nftItem) => {
          if (nftItem.url == selectedNftItem.url) {
            nftItem.selected = true
          }
        })
        this.lastSelectedNft = selectedNftItem
        console.log(`Selected NFT:`)
        console.log(this.lastSelectedNft)
      }
    },
    async handleEthereum() {
      web3 = new Web3(Web3.givenProvider)
      console.log(web3)
      const { ethereum } = window
      if (ethereum && ethereum.isMetaMask) {
        console.log('Ethereum successfully detected!')
        await this.accountInfo()
      } else {
        console.log('Please install MetaMask!')
      }
    },
    async accountInfo() {
      if (this.address) {
        let prevAddress = this.address
        let newAddress = await this.addressLookup()
        if (prevAddress != newAddress) {
          this.address = newAddress
          this.isblocked = false
        }
      } else {
        this.address = await this.addressLookup()
      }
    },
    async addressLookup() {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      const account = accounts[0]
      return account
    },
    async onGiftNFT() {
      console.log('NFT GIFT confirm Pressed')
      // Connect Metamask
      if (!this.address) {
        await this.handleEthereum()
      }
      // Approve NFT
      const ACC721Contract = new web3.eth.Contract(ACC721.abi, this.acc721ContractsAddress, { transactionConfirmationBlocks: 3 })
      const ACC1155Contract = new web3.eth.Contract(ACC1155.abi, this.acc1155ContractsAddress, { transactionConfirmationBlocks: 3 })
      const CourierContract = new web3.eth.Contract(Courier.abi, this.courierContractAddress)
      const ERC721Contract = new web3.eth.Contract(ERC721.abi, this.lastSelectedNft.contract_address, { transactionConfirmationBlocks: 3 })
      const ERC1155Contract = new web3.eth.Contract(ERC1155.abi, this.lastSelectedNft.contract_address, { transactionConfirmationBlocks: 3 })
      var tx
      if (this.itemId) {
        if (this.lastSelectedNft.contractType == 'ERC721') {
          console.log(this.acc721ContractsAddress)
          // Check if nft contract is approved
          let isApproved = await ERC721Contract.methods.isApprovedForAll(this.address, this.acc721ContractsAddress).call()
          console.log(`contract already approved: ${isApproved}`)
          if (isApproved) {
            // 721 Safe Transfer only
            console.log(`Already Approved NFT`)
            this.txProgress = 'transfering'

            ACC721Contract.methods
              .send721(this.lastSelectedNft.contract_address, this.lastSelectedNft.token_id, this.itemId)
              .send({ from: this.address, value: 0 })
              .on('error', function (error) {
                console.log(error)
                this.txProgress = ''
                this.warningMessage = error.message
                // setTimeout(() => {
                //   this.onGiftNFT()
                // }, 3000)
              })
              .then((receipt) => {
                // will be fired once the receipt is mined
                console.log(`recipt: ${receipt}`)
                console.log(`Successfully Gifted NFT`)
                this.txProgress = 'done'
              })
          } else {
            this.txProgress = 'approving'
            // 721 Approve and Safe Transfer
            ERC721Contract.methods
              .setApprovalForAll(this.acc721ContractsAddress, true)
              .send({ from: this.address, value: 0 })
              .on('error', function (error) {
                console.log(error)
                this.txProgress = ''
                this.warningMessage = error.message
                // setTimeout(() => {
                //   this.onGiftNFT()
                // }, 3000)
              })
              .then((receipt) => {
                // will be fired once the receipt is mined
                console.log(`recipt: ${receipt}`)
                console.log(`Successfully Approved NFT`)
                // Safe Transfer
                this.txProgress = 'transfering'
                ACC721Contract.methods
                  .send721(this.lastSelectedNft.contract_address, this.lastSelectedNft.token_id, this.itemId)
                  .send({ from: this.address, value: 0 })
                  .on('error', function (error) {
                    console.log(error)
                    this.txProgress = 'approving'
                    this.warningMessage = error.message
                    // setTimeout(() => {
                    //   this.onGiftNFT()
                    // }, 3000)
                  })
                  .then((receipt) => {
                    // will be fired once the receipt is mined
                    console.log(`recipt: ${receipt}`)
                    console.log(`Successfully Gifted NFT`)
                    this.txProgress = 'done'
                  })
              })
          }
        } else if (this.lastSelectedNft.contractType == 'ERC1155') {
          // 1155s
          let isApproved = await ERC1155Contract.methods.isApprovedForAll(this.address, this.acc1155ContractsAddress).call()
          if (isApproved) {
            // 1155 Safe Transfer only
            console.log(`Already Approved NFT`)
            this.txProgress = 'transfering'

            ACC1155Contract.methods
              .send1155(this.lastSelectedNft.contract_address, this.lastSelectedNft.token_id, 1, this.itemId)
              .send({ from: this.address, value: 0 })
              .on('error', function (error) {
                console.log(error)
                this.txProgress = ''
                this.warningMessage = error.message
                // setTimeout(() => {
                //   this.onGiftNFT()
                // }, 3000)
              })
              .then((receipt) => {
                // will be fired once the receipt is mined
                console.log(`recipt: ${receipt}`)
                console.log(`Successfully Gifted NFT`)
                this.txProgress = 'done'
              })
          } else {
            this.txProgress = 'approving'
            // 1155 Approve and Safe Transfer
            ERC1155Contract.methods
              .setApprovalForAll(this.acc1155ContractsAddress, true)
              .send({ from: this.address, value: 0 })
              .on('error', function (error) {
                console.log(error)
                this.txProgress = ''
                this.warningMessage = error.message
                // setTimeout(() => {
                //   this.onGiftNFT()
                // }, 3000)
              })
              .then((receipt) => {
                // will be fired once the receipt is mined
                console.log(`recipt: ${receipt}`)
                console.log(`Successfully Approved NFT`)
                // Safe Transfer
                this.txProgress = 'transfering'
                ACC1155Contract.methods
                  .send1155(this.lastSelectedNft.contract_address, this.lastSelectedNft.token_id, 1, this.itemId)
                  .send({ from: this.address, value: 0 })
                  .on('error', function (error) {
                    console.log(error)
                    this.txProgress = 'approving'
                    this.warningMessage = error.message
                    // setTimeout(() => {
                    //   this.onGiftNFT()
                    // }, 3000)
                  })
                  .then((receipt) => {
                    // will be fired once the receipt is mined
                    console.log(`recipt: ${receipt}`)
                    console.log(`Successfully Gifted NFT`)
                    this.txProgress = 'done'
                  })
              })
          }
        }
      } else {
        // Lucky Holder
        if (this.lastSelectedNft.contractType == 'ERC721') {
          // Set lucky Reciever
          this.luckyReciever = ACC721Contract.once(
            'LuckyHolder',
            {
              sender: this.address,
            },
            (error, event) => {
              console.log(event.returnValues)
              this.luckyReciever = event.returnValues[0]
            },
          )
          ERC721Contract.methods
            .safeTransferFrom(this.address, this.courierContractAddress, this.lastSelectedNft.token_id)
            .send({ from: this.address, value: 0 })
            .on('error', function (error) {
              console.log(error)
              this.txProgress = ''
              this.warningMessage = error.message
              // this.onGiftNFT()
            })
            .then((receipt) => {
              console.log(`recipt: ${receipt}`)
              this.txProgress = 'done'
            })
        } else if (this.lastSelectedNft.contractType == 'ERC1155') {
          let nftBalance = await ERC1155Contract.methods.balanceOf(this.address, this.lastSelectedNft.token_id).call()
          // Set lucky Reciever
          this.luckyReciever = await ACC1155Contract.once(
            'LuckyHolder1155',
            {
              sender: this.address,
            },
            (error, event) => {
              console.log(event.returnValues)
              this.luckyReciever = event.returnValues[0]
            },
          )
          tx = await ERC1155Contract.methods
            .safeTransferFrom(this.address, this.courierContractAddress, this.lastSelectedNft.token_id, nftBalance, [])
            .send({ from: this.address, value: 0 })
            .on('error', function (error) {
              console.log(error)
              this.txProgress = ''
              this.warningMessage = error.message
              // this.onGiftNFT()
            })
            .then((receipt) => {
              console.log(`recipt: ${receipt}`)
              this.txProgress = 'done'
            })
        }
      }
    },
    // Get NFTs from Asset API
    async getNFTsOwned(offset) {
      if (this.address) {
        // Create Params
        var params = new URLSearchParams()
        params.append('owner', this.address)
        params.append('order_direction', 'asc')
        params.append('offset', offset)
        params.append('limit', 50)
        var query = {
          headers: { 'X-API-KEY': "3dc45512b01949868961e4842026021e" },
          params: params,
        }
        // Axios Call
        try {
          await axios.get(this.baseAssetsURL, query).then((response) => {
            if (response.status == 200) {
              let assets = response.data.assets
              if (assets) {
                assets.forEach((asset) => {
                  let nftItem = {
                    name: asset.collection.name ? asset.collection.name : asset.asset_contract.name,
                    token_id: asset.token_id,
                    contract_address: asset.asset_contract.address,
                    image_url: asset.image_thumbnail_url ? asset.image_thumbnail_url : asset.collection.image_url ? asset.collection.image_url : asset.asset_contract.image_url,
                    url: asset.permalink,
                    selected: false,
                    contractType: asset.asset_contract.schema_name,
                    lazy_minted: asset.asset_contract.schema_name == 'ERC1155' && asset.num_sales == 0 && asset.creator.address == this.address,
                  }
                  this.nftItems.push(nftItem)
                  // dev purpose only
                  // Array.apply(null, Array(11)).forEach(() => {
                  // 	this.nftItems.push(nftItem)
                  // })
                })
                console.log(`NFTItems:`)
                console.log(this.nftItems)
              } else {
                console.log('No NFTs deposited')
              }
            } else {
              console.log('Error: \n', response)
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    async load_batch_nft_data() {
      if (this.nftItems.length % 50 == 0) {
        this.continue_loading = false
        console.log('Continue infinite loading')
        await this.getNFTsOwned(this.nftItems.length)
        this.continue_loading = true
      } else {
        this.continue_loading = false
        console.log('infinite loading Done')
      }
    },
    isOwnerComputed() {
      console.log(this.ownedItems)
      return this.ownedItems.includes(String(this.itemId))
    },
    onGoBack() {
      console.log('Go Back')
      this.pageProgress = ''
    },
  },
  computed: {},
}
</script>

<style>
</style>