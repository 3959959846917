<template>
  <div class="container mx-auto h-screen flex px-4 items-center justify-center flex-col">
    <div class="text-center lg:w-2/3 w-full">
      <div class="flex flex-col justify-center">
        <div class="flex flex-col items-center justify-center gap-y-0.5">
          <img :src="selectedNftItem.image_url ? selectedNftItem.image_url : require(`../assets/icons/no_image_asset.png`)" alt="" class="object-contain" />
          <a :href="osBaseUrl + selectedNftItem.contract_address + '/' + selectedNftItem.token_id" target="_blank" class="link underline font-main font-regular text-primary text-xs">{{ selectedNftItem.name }} #{{ selectedNftItem.token_id }}</a>
        </div>
        <div class="mt-8 flex flex-col">
          <div class="text-4xl font-bold text-center mb-2">{{ txProgress == 'approving' ? "Approve Your NFT for Transfer" : (txProgress == 'transfering' ? "Transfer your NFT" : (txProgress == 'done' ? 'AWESOME THAT WORKED 🤩' : 'ARE YOU SURE?')) }}</div>
          <div class="text-xl font-medium text-center">
            {{ (txProgress == 'approving' || txProgress == 'transfering') ? 'Please confirm the transactions in your wallet app.' : txProgress == 'done' ? 'Your deliverly has been processed!' : 'Would you like to send the NFT?' }}
          </div>
          <div v-if="luckyReciever && luckyReciever != 'undefined' && !isNaN(luckyReciever)" class="font-medium text-center text-md text-primary">
            Recieved by <router-link :to="{ name: 'Claim', params: { number: Number(luckyReciever) }}" class="underline">DOJI #{{luckyReciever}}</router-link>
          </div>
        </div>
        <div class="container flex flex-col items-center justify-center mt-6">
          <!-- If Owner -->
          <button v-if="txProgress == ''" @click="onConfirmNFT()" class="w-44 inline-flex justify-center bg-accent uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">CONFIRM</button>
          <!-- <button v-if="isOwner" @click="onPinNFT()" class="w-44 inline-flex justify-center uppercase text-primary text-xl text-center font-bold border-4 border-accent py-2 px-6 focus:outline-none text-lg mb-4">PIN MORE</button> -->
          <!-- Lottie -->
          <lottie v-else-if="txProgress == 'approving' || txProgress == 'transfering'" :height="150" class="max-h-40 w-24" :options="lottieOptions" @animCreated="handleAnimation" />
          <!-- <button v-else-if="txProgress == 'processing'" disabled class="w-44 inline-flex justify-center bg-accent uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">PROCESSING</button> -->
          <!-- <button v-else-if="(txProgress == 'done')" disabled class="w-44 inline-flex justify-center bg-accent uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">DONE</button> -->
        </div>
        <div class="text-red-500 text-sm font-regular text-center mb-2">{{warningMessage}}</div>
        <button v-if="txProgress == '' || txProgress == 'error'" @click="onGoBack" class="text-primary underline text-xl capitalize mt-2">go back</button>
        <router-link v-else-if="txProgress == 'done'" :to="{ name: 'Home' }" @click="onGoBack" class="text-primary underline text-xl capitalize mt-2">go back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'vue-lottie/src/lottie.vue'
import * as animationData from "@/assets/lottie/lottie_loading_animation.json";
export default {
  name: 'ConfirmGiftNFTView',
  props: {
    selectedNftItem: Object,
    txProgress: String,
    luckyReciever: String,
    warningMessage: String,
  },
  components: {
    lottie,
  },
  data() {
    return {
      osBaseUrl: process.env.VUE_APP_OS_BASE_URL,
      lottieAnimation: null,
      lottieOptions: { animationData: animationData.default }
    }
  },
  methods: {
    onConfirmNFT() {
      this.$emit('onConfirmNFT')
    },
    onGoBack() {
      this.$emit('onGoBack')
    },
    handleAnimation: function (lottieAnimation) {
      this.lottieAnimation = lottieAnimation;
    }
  },
}
</script>

<style>
</style>