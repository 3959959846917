<template>
  <div :class="['bg-black', display == 1 ? 'sticky top-0' : '']" style="z-index: 10000000">
    <nav id="page-wrap">
      <div id="nav" class="flex max-w-screen-2xl mx-auto py-2 px-2 items-center align-middle text-white gap-x-5">
        <a href="https://dojicrew.com"><img src="@/assets/images/nav_logo.png" class="h-auto w-14 py-2 self-center mr-auto" /></a>
        <div v-if="display == 0" class="w-full">
          <div class="flex justify-between w-full">
            <!-- Nav buttons -->
            <div class="flex align-middle items-center gap-x-5">
              <!-- <NuxtLink :to="{ path: '/', hash: '#about' }">ABOUT</NuxtLink> -->
              <!-- <NuxtLink :to="{ path: '/', hash: '#zingot' }">$ZINGOT</NuxtLink> -->
              <!-- <NuxtLink :to="{ path: '/', hash: '#art' }">ART</NuxtLink> -->
              <!-- <NuxtLink :to="{ path: '/', hash: '#roadmap' }">ROADMAP</NuxtLink> -->
              <!-- <NuxtLink :to="{path:'/',hash:'#boarding'}">BOARDING</NuxtLink> -->
              <a href="https://dojicrew.com/faq">FAQ</a>
              <a href="https://etherscan.io/address/0x5e9dc633830af18aa43ddb7b042646aadedcce81" target="_blank">CONTRACT</a>
              <!-- <a href="https://rug-marketing-python-jkjw3y7fg.mypinata.cloud/ipfs/QmVJ19iDtrM28DWe4yLkNQWffpif4cjvhwu5hf5zksEJd9" target="_blank">ABI</a> -->
              <a href="https://courier.dojicrew.com">COURIER</a>
              <a href="https://dojicrew.com/reveal">REVEAL</a>
              <a class="px-2" href="https://dojicrew.com/market">MARKET</a>
              <a class="px-2" href="https://dojicrew.com/book">BOOK</a>
              <a href="https://qnft.tech/collab" target="_blank"> COLLAB </a>
              <a class="px-2" href="https://dojicrew.com/pay">PAY</a>
            </div>

            <!-- Social/mint -->
            <div class="flex align-middle items-center">
              <!-- <div v-if="user_address" class="flex flex-row items-center justify-center font-bold">
                <button @click="logout" class="text-xs underline tracking-wider mr-2 upppercase">logout</button>
                <div class="font-bold text-sm mr-2 p-2 border-r border-primary">{{ user_zingot_balance | _digits }}z</div>
                <div class="flex items-center space-x-2 h-10 p-2 mr-3 bg-gray-600 overflow-hidden">
                  <img src="~/assets/icons/wallet-outline.svg" alt="" class="inset-0 w-6 h-6" />
                  <div class="flex-auto min-w-0">
                    <div class="inline-flex font-regular text-left text-primary text-xs tracking-wider uppercase">
                      {{ display_address | _shortenAddress }}
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div v-else @click="_connect_web3()" class="flex items-center justify-center space-x-2 h-10 p-2 mr-3 bg-gray-600 overflow-hidden font-bold cursor-pointer">
                <button class="font-bold text-sm align-text-bottom flex items-center justify-center my-auto py-auto uppercase">Connect Wallet</button>
              </div> -->
              <a href="https://opensea.io/collection/dojicrew" class="" target="_blank"><img class="h-6 w-6 xl:h-8 xl:w-8 m-auto mr-2 xl:mr-4 align self-auto" src="@/assets/images/opensea.png" alt="" /></a>
                  <a href="https://looksrare.org/collections/0x5e9dC633830Af18AA43dDB7B042646AADEDCCe81" class="" target="_blank"><img class="h-6 w-6 xl:h-8 xl:w-8 m-auto mr-2 xl:mr-4 align self-auto" src="@/assets/images/looks_icon_white.svg" alt="" /></a>
              <a href="https://twitter.com/dojicrew" class="" target="_blank"><img class="h-6 w-6 xl:h-8 xl:w-8 m-auto mr-2 xl:mr-4 align self-auto" src="@/assets/images/twitter.svg" alt="" /></a>
              <a href="https://instagram.com/dojicrew" class="" target="_blank"><img class="h-6 w-6 xl:h-8 xl:w-8 m-auto mr-2 xl:mr-4 align self-auto" src="@/assets/images/instagram.svg" alt="" /></a>
              <a href="https://discord.qnft.tech/" class="" target="_blank"><img class="h-6 w-6 xl:h-8 xl:w-8 m-auto mr-2 xl:mr-4 align self-auto" src="@/assets/images/discord.svg" alt="" /></a>
              <!-- <button @click="mint_pressed" class="bg-green-500 hover:bg-green-700 text-white text-center whitespace-nowrap font-bold py-2 px-4 font-bold justify-end">MINT | .044Ξ</button> -->
            </div>
          </div>
        </div>
        <div v-else class="inline-flex items-center justify-end w-full">
          <!-- <div v-if="user_address" class="flex flex-row items-center justify-center font-bold">
            <button @click="logout" class="text-xs underline tracking-wider mr-2 upppercase">logout</button>
            <div class="font-bold text-sm mr-2 p-2 border-r border-primary">{{ user_zingot_balance | _digits }}z</div>
            <div class="flex items-center space-x-2 h-10 p-2 mr-3 bg-gray-600 overflow-hidden">
              <img src="~/assets/icons/wallet-outline.svg" alt="" class="inset-0 w-6 h-6" />
              <div class="flex-auto min-w-0">
                <div class="inline-flex font-regular text-left text-primary text-xs tracking-wider uppercase">
                  {{ display_address | _shortenAddress }}
                </div>
              </div>
            </div>
          </div>
          <div v-else @click="_connect_web3()" class="flex items-center justify-center space-x-2 h-10 p-2 mr-3 bg-gray-600 overflow-hidden font-bold cursor-pointer">
            <button class="font-bold text-sm align-text-bottom flex items-center justify-center my-auto py-auto uppercase">Connect Wallet</button>
          </div> -->
          <Menu />
          <Slide right class="h-full">
            <!-- <NuxtLink :to="{ path: '/', hash: '#about' }">ABOUT</NuxtLink> -->
            <!-- <NuxtLink :to="{ path: '/', hash: '#zingot' }">$ZINGOT</NuxtLink> -->
            <!-- <NuxtLink :to="{ path: '/', hash: '#art' }">ART</NuxtLink> -->
            <!-- <NuxtLink :to="{ path: '/', hash: '#roadmap' }">ROADMAP</NuxtLink> -->
            <!-- <NuxtLink :to="{path:'/',hash:'#boarding'}">BOARDING</NuxtLink> -->
            <a href="https://dojicrew.com/faq">FAQ</a>
            <a href="https://etherscan.io/address/0x5e9dc633830af18aa43ddb7b042646aadedcce81" target="_blank">SMART CONTRACT</a>
            <!-- <a href="https://rug-marketing-python-jkjw3y7fg.mypinata.cloud/ipfs/QmVJ19iDtrM28DWe4yLkNQWffpif4cjvhwu5hf5zksEJd9" target="_blank">ABI</a> -->
            <a href="https://courier.dojicrew.com">COURIER</a>
            <a href="https://dojicrew.com/reveal">REVEAL</a>
            <a href="https://dojicrew.com/market">MARKETPLACE</a>
            <a class="" href="https://dojicrew.com/book">BOOK</a>
            <a href="https://qnft.tech/collab" target="_blank"> COLLAB </a>
            <a class="" href="https://dojicrew.com/pay">PAY</a>

            <div class="absolute inset-x-0 bottom-0">
              <div class="flex flex-col justify-center w-full gap-y-5">
                <!-- <button @click="mint_pressed" class="bg-green-500 hover:bg-green-700 text-white text-center font-bold justify-center" style="padding-top: 0.5rem !important; padding-bottom: 0.5rem !important">MINT | .044Ξ</button> -->
                <div class="flex gap-x-5 justify-center">
                  <a href="https://opensea.io/collection/dojicrew" class="" target="_blank"><img class="h-8 w-8" src="@/assets/images/opensea.png" alt="" /></a>
                  <a href="https://looksrare.org/collections/0x5e9dC633830Af18AA43dDB7B042646AADEDCCe81" class="" target="_blank"><img class="h-8 w-8" src="@/assets/images/looks_icon_white.svg" alt="" /></a>
                  <a href="https://twitter.com/dojicrew" class="" target="_blank"><img class="h-8 w-8" src="@/assets/images/twitter.svg" alt="" /></a>
                  <a href="https://instagram.com/dojicrew" class="" target="_blank"><img class="h-8 w-8" src="@/assets/images/instagram.svg" alt="" /></a>
                  <a href="https://discord.qnft.tech/" class="" target="_blank"><img class="h-8 w-8" src="@/assets/images/discord.svg" alt="" /></a>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
  name: 'navigation',
  components: {
    Slide,
  },
  props: {},
  data() {
    return {
      display: window.innerWidth > 1000 ? 0 : 1,
    }
  },
  computed: {
    // user_address() {
    //   return this.$store.state.user_info.address
    // },
    // display_address() {
    //   const user_info = this.$store.state.user_info
    //   return this.user_address ? (user_info.ens_name ? user_info.ens_name : user_info.address) : ''
    // },
    // user_zingot_balance() {
    //   return this.$store.state.user_info.zingot_balance ? parseFloat(this.$store.state.user_info.zingot_balance).toFixed() : 0
    // },
  },
  methods: {
    // onResize() {
    //   if (window.innerWidth > 1000) {
    //     this.display = 0
    //   } else {
    //     this.display = 1
    //   }
    // },
    // async mint_pressed() {
    //   await this._mint_doji(1)
    // },
  },
  // created() {
  //   // window.addEventListener('resize', this.onResize)
  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.onResize)
  // },
}
</script>

<style>
.bm-burger-button {
  top: 0 !important;
  position: relative !important;
  left: auto !important;
  right: auto !important;
}
.bm-menu {
  background-color: black !important;
  color: white !important;
  overflow-y: visible !important;
}
.bm-item-list {
  color: white !important;
  overflow-y: visible !important;
}
.bm-cross {
  background: white !important;
}
.bm-burger-bars.line-style {
  background-color: white;
}

.bm-item-list a {
  padding: 0.4em !important;
}
</style>
