<template>
  <div id="app" class="relative">
    <Navigation />
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  components: {
    Navigation,
  },
};
</script>

<style>
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.route-enter-active,
.route-leave-active {
  transition: all 0.5s ease-in-out;
}
</style>
