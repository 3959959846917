<template>
  <div class="grid grid-cols-3 md:grid-cols-4 overflow-y-auto hide-scroll-bar items-start justify-center gap-2 p-6 md:mb-4 max-h-96">
    <div v-for="nftItem in nftItems" :key="nftItem.url" class="flex flex-col items-center justfy-center p-0.5">
      <div @click="nftSelected(nftItem)" :class="nftItem.selected ? 'ring-4 ring-accent ring-offset-2' : 'ring-0'" class="mb-3">
        <img :src="nftItem.image_url ? nftItem.image_url : require(`../assets/icons/no_image_asset.png`)" alt="" class="object-contain cursor-pointer" :class="nftItem.lazy_minted == true ? 'opacity-50' : 'opacity-100'" />
      </div>
      <a :href="osBaseUrl + nftItem.contract_address + '/' + nftItem.token_id" target="_blank" class="link underline font-main font-regular text-primary text-xs">{{ nftItem.name }} #{{ nftItem.token_id.slice(0, 4) }}</a>
    </div>
    <InfiniteLoading :continue_loading="continue_loading" @continue="load_batch_nft_data"></InfiniteLoading>
  </div>
</template>

<script>
import InfiniteLoading from "@/components/InfiniteLoadingModule.vue"

export default {
  name: 'NftGrid',
  props: {
    nftItems: Array,
    continue_loading: Boolean
  },
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      baseAssetsURL: process.env.VUE_APP_BASE_ASSETS_URL,
      tokenIds: null,
      nftContracts: null,
      osBaseUrl: process.env.VUE_APP_OS_BASE_URL,
    }
  },
  async mounted() {},
  watch: {},
  methods: {
    nftSelected(selectedNftItem) {
      // show warning for lazy mints
      this.$emit("did_select_lazy_mint", selectedNftItem.lazy_minted)
      // Update grid selected
      this.$emit('updateGridSelection', selectedNftItem)
    },
    load_batch_nft_data() {
      this.$emit('load_batch_nft_data')
    }
  },
}
</script>