<template>
  <section class="relative text-primary body-font overflow-x-hidden font-main">
    <!-- Main section -->
    <div class="z-6 container mx-auto w-full h-screen flex px-4 py-24 items-center justify-center flex-col animate-fade-in-up">
      <div class="text-center lg:w-2/3 w-full">
        <img src="../assets/icons/AME_0x-2_BLUE.png" alt="" class="h-52 animate-float object-contain mx-auto mb-2" />
        <h1 class="title-font text-2xl sm:text-4xl mb-2 font-bold uppercase">hi! I'm the courier!</h1>
        <p class="mb-8 text-md sm:text-xl leading-relaxed">I can help check your <a href="https://dojicrew.com/" target="_blank" class="underline">DOJI</a> for packages and gifts!</p>
        <div class="flex flex-col md:flex-row items-center justify-center gap-x-4 mb-6">
          <form @submit="viewClaimable" class="w-full flex flex-col items-center justify-center">
          <input type="search" placeholder="ENTER YOUR DOJI #" @keyup.enter="viewClaimable" v-model="searchText" class="w-full inline-flex justify-center bg-gray-600 text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg rounded-none" style="-webkit-appearance: none" />
        </form>
        <button @click="setRandom" class="underline font-body font-bold text-sm text-center mt-4 md:mt-0">Random</button>
        </div>
        <div class="flex flex-col md:flex-row items-center justify-center gap-y-4 md:gap-x-6 mb-12">
          <button @click="viewClaimable" class="w-56 h-10 px-12 bg-accent text-primary font-heading font-bold text-xl">VIEW DOJI</button>
          <button @click="sendGift" class="w-56 h-10 px-12 border-4 border-accent text-primary font-heading font-bold text-xl">SEND GIFT</button>
        </div>
        <!-- <button @click="sendRandomGift" class="w-56 h-10 border-4 border-accent text-primary font-heading font-bold text-md mt-4">SEND RANDOM GIFT</button> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      searchText: '',
      marqueeTextElem: null,
      upperLimit:11111,
      randomSearchText: "?????"
    }
  },
  mounted() {},
  methods: {
    setRandom() {
      this.searchText = this.randomSearchText
    },
    viewClaimable() {
      // Dismiss when searchbox is empty
      if (!this.searchText) {
        return
      }
      var itemId = 0
      if (this.searchText == this.randomSearchText) {
        itemId = this.generateRandomId(1, 11111)
      } else {
        itemId = parseInt(this.searchText.match(/\d/g).join(''), 10)
      }
      console.log(itemId)
      if (itemId > 0 && itemId <= this.upperLimit) {
        this.$router.push({ name: 'Claim', params: { number: itemId } })
      } else {
        return
      }
    },
    sendGift() {
      // Dismiss when searchbox is empty
      if (!this.searchText) {
        return
      }
      var itemId = 0
      if (this.searchText == this.randomSearchText) {
        this.sendRandomGift()
        return
      } else {
        itemId = parseInt(this.searchText.match(/\d/g).join(''), 10)
      }
      console.log(itemId)
      if (itemId > 0 && itemId <= this.upperLimit) {
        this.$router.push({ name: 'Gift', params: { number: itemId } })
      } else {
        return
      }
    },
    sendRandomGift() {
      this.$router.push({ name: 'Gift', params: { number: null } })
    },
    generateRandomId(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
  },
}
</script>
