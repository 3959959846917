<template>
  <div>
    <!-- Top Right Info -->
    <div class="hidden md:block absolute top-4 right-4">
      <div class="flex flex-col items-center justify-center md:justify-end gap-y-2">
        <a :href="osBaseAssetURL + itemId" target="_blank">
          <img v-if="profileImageURL" :src="profileImageURL" alt="" class="object-contain w-16 rounded-full border-2 border-primary" />
        </a>
        <div class="font-medium text-body text-xl">#{{ itemId }}</div>
      </div>
    </div>
    <!-- Mobile Center Info -->
    <div class="md:hidden absolute top-4 left-4 right-4">
      <div class="flex flex-col items-center justify-center md:justify-end">
        <a :href="osBaseAssetURL + itemId" target="_blank">
          <img v-if="profileImageURL" :src="profileImageURL" alt="" class="object-contain w-16 rounded-full border-2 border-primary" />
        </a>
        <div class="font-medium text-body text-xl">#{{ itemId }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: "ProfileIcon",
  props: {
    itemId: Number,
  },
  data() {
    return {
      osBaseAssetURL: process.env.VUE_APP_OS_BASE_URL + process.env.VUE_APP_DOJI_CONTRACT + '/',
      baseMetaURL: process.env.VUE_APP_BASE_DOJI_META_URL,
      profileImageURL: null,
    }
  },
  async mounted() {
    await this.loadMetadata();
  },
  methods: {
    async loadMetadata() {
      // Return if item id is null
      if (!this.itemId) {
        return
      }
      // axios call
      try {
        await axios.get(this.baseMetaURL + String(this.itemId)).then((response) => {
          if (response.status == 200) {
            let data = response.data
            this.profileImageURL = data.image
          } else {
            console.log(response)
          }
        })
      } catch (error) {
        console.log(error)
      }

    },
  },
}
</script>

<style>
</style>