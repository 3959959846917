import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Claim from '../views/Claim.vue'
import Gift from '../views/GIFT.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'DOJI COURIER - SEND AND RECEIVE GIFT NFTs', desc: 'Send and receive gift NFTs directly to your DOJIs!' }
  },
  {
		path: '/doji/:number',
		name: 'Claim',
		component: Claim,
    meta: { title: 'DOJI COURIER - SEND AND RECEIVE GIFT NFTs', desc: 'Send and receive gift NFTs directly to your DOJIs!' }
  },
  {
		path: '/gift/:number',
		name: 'Gift',
		component: Gift,
    meta: { title: 'DOJI COURIER - SEND AND RECEIVE GIFT NFTs', desc: 'Send and receive gift NFTs directly to your DOJIs!' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  next()
});

export default router
