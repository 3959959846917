<template>
  <div class="container mx-auto h-screen flex md:px-4 items-center justify-center flex-col">
    <div class="text-center lg:w-2/3 w-full">
      <div class="flex flex-col justify-center mt-8 md:mt-0">
        <div v-if="isOwner" class="flex flex-col items-center justify-center gap-y-0.5">
          <img :src="selectedNftItem.image_url ? selectedNftItem.image_url : require(`../assets/icons/no_image_asset.png`)" alt="" class="object-contain" />
          <a :href="osBaseUrl + selectedNftItem.contract_address + '/' + selectedNftItem.token_id" target="_blank" class="link underline font-main font-regular text-primary text-xs">{{ selectedNftItem.name }} #{{ selectedNftItem.token_id.slice(0, 4) }}</a>
        </div>
        <div class="mt-2 md:mt-8 flex flex-col max-w-lg text-center mx-auto">
          <div class="text-4xl font-bold text-center mb-2">{{ isOwner ? (txProgress == 'processing' ? 'Your transaction is processing' : txProgress == 'done' ? 'AWESOME THAT WORKED🤩' : 'STAY SAFE!') : 'THIS IS NOT YOUR DOJI!' }}</div>
          <div class="text-xl font-medium text-center">
            {{ txProgress == 'processing' ? 'Please wait until it completes' : txProgress == 'done' ? 'Your deliverly has been processed!' : 'Do you know who sent you this NFT?' }}
          </div>
          <div v-if="isOwner && txProgress == ''" class="text-xl font-medium text-center">
            Please <a :href="osCollectionUrl + selectedNftItem.slug" target="_blank" class="underline">view the collection</a> on Opensea before proceeding. <br /><br />
            Claiming NFTs from unknown senders and unverified collections puts yourself, your wallet, and your collection at risk.
          </div>
        </div>
        <div class="container flex flex-col items-center justify-center mt-6">
          <!-- If Owner -->
          <div v-if="isOwner && txProgress == '' && showMessage == true" class="text-md font-regular text-center text-accent mb-2">Please verify on Opensea by clicking the button below.</div>
          <div v-if="isOwner && txProgress == ''">
            <label class="inline-flex items-center">
              <input v-model="didVeridy" type="checkbox" class="form-checkbox" style="-webkit-appearance: none" />
              <span class="ml-2">I am aware of the risks. Please let me claim.</span>
            </label>
          </div>
          <button v-if="isOwner && txProgress == '' && !didVeridy" @click="onVerifyClicked()" :class="didVeridy ? 'bg-gray-600' : 'bg-accent'" class="inline-flex justify-center uppercase text-primary text-lg text-center font-bold py-2 px-6 focus:outline-none text-lg mb-4">VERIFY OS COLLECTION</button>
          <button v-else-if="isOwner && txProgress == ''" @click="onConfirmNFT()" :class="didVeridy ? 'bg-accent' : 'bg-gray-600'" class="w-44 inline-flex justify-center uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">CONFIRM</button>
          <!-- <button v-if="isOwner && txProgress == 'processing'" disabled class="w-44 inline-flex justify-center uppercase text-primary text-xl text-center font-bold border-4 border-accent py-2 px-6 focus:outline-none text-lg mb-4">PROCESSING</button> -->
          <!-- <button v-if="isOwner && txProgress == 'done'" disabled class="w-44 inline-flex justify-center uppercase text-primary text-xl text-center font-bold border-4 border-accent py-2 px-6 focus:outline-none text-lg mb-4">DONE</button> -->
          <!-- Lottie -->
          <lottie v-else-if="txProgress == 'approving' || txProgress == 'transfering'" :height="150" class="max-h-40 w-24" :options="lottieOptions" @animCreated="handleAnimation" />
          <!-- If not Owner -->
          <button v-if="!isOwner" @click="onPinNFT()" class="w-44 inline-flex justify-center bg-accent uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg mb-4">SEND GIFTS</button>
        </div>
        <router-link :to="{ name: 'Home' }" class="text-primary underline text-xl capitalize mt-2">go back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'vue-lottie/src/lottie.vue'
import * as animationData from '@/assets/lottie/lottie_loading_animation.json'

export default {
  name: 'ConfirmNFTView',
  props: {
    isOwner: Boolean,
    txProgress: String,
    selectedNftItem: Object,
  },
  components: {
    lottie,
  },
  data() {
    return {
      osBaseUrl: process.env.VUE_APP_OS_BASE_URL,
      osCollectionUrl: process.env.VUE_APP_OS_COLLECTION_URL,
      lottieAnimation: null,
      lottieOptions: { animationData: animationData.default },
      didVeridy: false,
      showMessage: false,
    }
  },
  methods: {
    onConfirmNFT() {
      if (!this.didVeridy) {
        // this.showMessage = true
        return
      }
      this.$emit('onConfirmNFT')
    },
    onPinNFT() {
      this.$emit('onPinNFT')
    },
    onVerifyClicked() {
      let url = this.osBaseUrl + this.selectedNftItem.contract_address + '/' + this.selectedNftItem.token_id
      window.open(url, '_blank').focus()
      this.didVeridy = true
      this.showMessage = false
    },
    handleAnimation: function (lottieAnimation) {
      this.lottieAnimation = lottieAnimation
    },
  },
  watch: {
    // didVeridy(newVal) {
    //   this.showMessage = !newVal
    // }
  }
}
</script>

<style>
</style>