<template>
  <infinite-loading @infinite="infiniteHandler" :distance="1" spinner="waveDots">
    <div slot="no-more"></div>
    <div slot="no-results"></div>
  </infinite-loading>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'InfiniteLoadingModule',
  props: {
    continue_loading: Boolean,
  },
  components: {
    'infinite-loading': InfiniteLoading,
  },
  methods: {
    infiniteHandler($state) {
      if (this.continue_loading) {
        this.$emit('continue', true)
        $state.loaded()
      } else {
        $state.complete()
        console.log('completed infinite loading')
      }
    },
  },
}
</script>