<template>
  <div class="container mx-auto h-screen flex px-4 items-center justify-center flex-col">
    <div class="text-center lg:w-2/3 w-full">
      <div class="flex flex-col justify-center">
        <div class="mt-8 flex flex-col">
          <div class="text-4xl font-bold text-center mb-2">Your NFT Collection 😍</div>
          <div class="text-xl font-medium text-center">Choose one to send as a gift to {{itemInfoText}}</div>
        </div>
        <div class="my-2 h-1/3">
          <NftGrid v-if="nftItems.length" :nftItems="nftItems" @updateGridSelection="updateGridSelection" @load_batch_nft_data="load_batch_nft_data" @did_select_lazy_mint="did_select_lazy_mint" :continue_loading="continue_loading" />
          <!-- Lottie -->
          <lottie v-else-if="!nftItems.length" :height="150" class="max-h-40 w-24" :options="lottieOptions" @animCreated="handleAnimation" />
        </div>
        <div v-if="nftItems.length" class="w-full mb-4">
          <button @click="confirmSelection()" class="w-full inline-flex justify-center bg-accent uppercase text-primary text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg">SEND</button>
          <button v-if="isblocked" disabled class="w-full inline-flex justify-center bg-warning uppercase text-background text-xl text-center font-bold border-0 py-2 px-6 focus:outline-none text-lg">you don't own this</button>
          <div v-if="showLazyMintError" class="font-regular text-lg text-center text-red-500">This NFT is lazy minted and not able to be sent. <br> To gift please send this NFT to another wallet and try again.</div>
        </div>
        <router-link :to="{ name: 'Home' }" class="text-primary underline text-xl capitalize">go back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NftGrid from '../components/NftGrid.vue'
import lottie from 'vue-lottie/src/lottie.vue'
import * as animationData from "@/assets/lottie/lottie_loading_animation.json";

export default {
  name: "ViewGiftableView",
  components: {
    NftGrid,
    lottie,
  },
  props: {
    itemId: String,
    nftItems: Array,
    isblocked: Boolean,
    continue_loading: Boolean,
  },
  data() {
    return {
      itemInfoText: (!this.itemId || this.itemId == 'null') ? "RANDOM minted DOJI" : 'DOJI #' + this.itemId,
      lottieAnimation: null,
      lottieOptions: { animationData: animationData.default },
      showLazyMintError: false,
    }
  },
  methods: {
    updateGridSelection(selectedNftItem) {
      this.$emit('updateGridSelection', selectedNftItem)
    },
    confirmSelection() {
      this.$emit('confirmSelection')
    },
    handleAnimation: function (lottieAnimation) {
      this.lottieAnimation = lottieAnimation;
    },
    load_batch_nft_data() {
      this.$emit('load_batch_nft_data')
    },
    did_select_lazy_mint(isSelected) {
      this.showLazyMintError = isSelected
    }
  },
}
</script>

<style>
</style>